import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import ImageLinkList from '../components/imageLinkList'

const FlexRowCenteredWrap = styled.div`
  justify-content: center;
  display: flex;
  margin-top: 20px;
  & > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 650px;
    margin-bottom: ${({ marginBottom }) => marginBottom || 'initial'};
  }
`

const ReleasesPage = ({ data }) => {
  return (
    <FlexRowCenteredWrap>
      <div>
        <ImageLinkList
          items={data.releases.nodes
            .filter(
              (release) =>
                !!data.projects.nodes.find(
                  (project) =>
                    project.uri === release.artistUri ||
                    release.artistUri === 'none'
                )
            )
            .sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate))}
          uriPrefix="de/releases"
        />
      </div>
    </FlexRowCenteredWrap>
  )
}

export const pageQuery = graphql`
  query ReleasesQuery {
    releases: allReleasesJson(sort: { order: ASC, fields: releaseDate }) {
      nodes {
        releaseDate
        artistUri
        image
        uri
      }
    }
    projects: allProjectsJson(filter: { type: { eq: "solo" } }) {
      nodes {
        uri
      }
    }
  }
`

export default ReleasesPage
